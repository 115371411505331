<template>
  <div>
    <a-form-item label="内部API服务器域名">
      <a-input
        v-decorator="['china_broadnet_api_account_form.api_server_domain', {
          normalize: this.$lodash.trim,
          initialValue: chinaBroadnetApiAccountForm.api_server_domain,
          rules: [{ required: true, message: '请输入内部API服务器域名' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="接口类型">
      <a-select
        @change="selectAccountType"
        v-decorator="['china_broadnet_api_account_form.account_type', {
          initialValue: chinaBroadnetApiAccountForm.account_type,
          rules: [{ required: true, message: '请选择接口类型' }
          ]
        }]"
      >
        <a-select-option key="CMP" value="CMP">
          CMP
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="API访问路径">
      <a-input
        v-decorator="['china_broadnet_api_account_form.api_url', {
          normalize: this.$lodash.trim,
          initialValue: chinaBroadnetApiAccountForm.api_url,
          rules: [{ required: true, message: '请输入API访问路径' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="APP ID" v-show="isShowCMPParams">
      <a-input
        v-decorator="['china_broadnet_api_account_form.app_id', {
          normalize: this.$lodash.trim,
          initialValue: chinaBroadnetApiAccountForm.app_id,
          rules: [{ required: isShowCMPParams, message: '请输入APP ID' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="APP SECRET" v-show="isShowCMPParams">
      <a-input
        v-decorator="['china_broadnet_api_account_form.app_secret', {
          normalize: this.$lodash.trim,
          initialValue: chinaBroadnetApiAccountForm.app_secret,
          rules: [{ required: isShowCMPParams, message: '请输入APP SECRET' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="OPEN ID" v-show="isShowCMPParams">
      <a-input
        v-decorator="['china_broadnet_api_account_form.open_id', {
          normalize: this.$lodash.trim,
          initialValue: chinaBroadnetApiAccountForm.open_id,
          rules: [{ required: isShowCMPParams, message: '请输入OPEN ID' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="调用限频">
      <a-input-group compact>
        <a-form-item :style="{ display: 'inline-block', width: '55%', marginBottom: 0 }">
          <a-space>
            <a-input-number
              style="width: 135px"
              :min="1"
              :max="10000000"
              v-decorator="['china_broadnet_api_account_form.rate_count', {
                initialValue: chinaBroadnetApiAccountForm.rate_count == 0 ? undefined : chinaBroadnetApiAccountForm.rate_count,
                rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
              }]"
            />
            <span>次</span>
          </a-space>
        </a-form-item>

        <a-form-item :style="{ display: 'inline-block', width: '40%', marginBottom: 0 }">
          <a-select
            style="width: 140px"
            v-decorator="['china_broadnet_api_account_form.rate_time_unit', {
              initialValue: chinaBroadnetApiAccountForm.rate_time_unit
            }]"
          >
            <a-select-option value="second">
              每秒
            </a-select-option>
            <a-select-option value="minute">
              每分
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-input-group>
    </a-form-item>
  </div>
</template>

<script>
export default {
  name: 'EditChinaBroadnetApiAccountForm',
  props: {
    chinaBroadnetApiAccountForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowCMPParams: this.chinaBroadnetApiAccountForm.account_type === 'CMP'
    }
  },
  methods: {
    selectAccountType(value) {
      this.isShowCMPParams = value === 'CMP'
    }
  }
}
</script>

<style scoped>

</style>
